function IconDelete() {
  return (
    <svg
      className="icon-delete"
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="#3067F3"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5556 4.44446V14.2222C11.5556 15.2041 10.7596 16 9.77778 16H2.66667C1.68483 16 0.888889 15.2041 0.888889 14.2222V4.44446H11.5556ZM9.77778 6.22223H2.66667V14.2222H9.77778V6.22223ZM6.22222 0C6.71314 0 7.11111 0.397969 7.11111 0.888889V1.77778H11.5556C12.0465 1.77778 12.4444 2.17575 12.4444 2.66667C12.4444 3.15759 12.0465 3.55556 11.5556 3.55556H0.888889C0.397969 3.55556 0 3.15759 0 2.66667C0 2.17575 0.397969 1.77778 0.888889 1.77778H5.33333V0.888889C5.33333 0.397969 5.7313 0 6.22222 0Z"
      />
    </svg>
  );
}

export default IconDelete;