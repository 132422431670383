function IconArrow() {
  return (
    <svg
      className="icon-arrow"
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="#7E84A3"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M11.7903 9.6129L11.7071 9.70711L6.70711 14.7071C6.34662 15.0676 5.77939 15.0953 5.3871 14.7903L5.29289 14.7071L0.292893 9.70711C-0.0976311 9.31658 -0.0976311 8.68342 0.292893 8.29289C0.653377 7.93241 1.22061 7.90468 1.6129 8.2097L1.70711 8.29289L5.001 11.586L5 1C5 0.447715 5.44772 0 6 0C6.55228 0 7 0.447715 7 1L7.001 11.584L10.2929 8.29289C10.6534 7.93241 11.2206 7.90468 11.6129 8.2097L11.7071 8.29289C12.0676 8.65338 12.0953 9.22061 11.7903 9.6129Z" />
    </svg>
  );
}

export default IconArrow;